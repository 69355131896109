.action-container {
    display: flex;
    flex-direction: row;
    width: 90%;
    margin: 5rem auto;
    align-items: center;

    @media(max-width:750px){
        flex-direction:column;
    }

    .interested {
        color: #33323D;
        font-size: 2.2rem;
        line-height: 2.2rem;
        max-width: 19rem;
        font-weight: 700;
        text-align: left;

        @media(max-width:750px){
            margin-bottom: 3rem;
            width: 100%;
            text-align: center;
        }

        @media(max-width:350px){
            font-size: 1.8rem;
    }
}   

    .action-line-container{

        width: 100%;
    .action-line {
        width: 90%;
        height: 1px;
        border: 0 none;
        background-color:black;

        @media(max-width:750px){
            display: none;
        }
    }
    }
}