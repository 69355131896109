
    
.flex{
    display: flex;
    width: 90%;
    margin: 0 auto;
    margin-top: 2.5rem;
    align-items: flex-end;


    .ripple{

        width: 100%;
        height: 600px;
        svg {
           
            // height: 100vh;
            display: block;
            left: 0;
            top: -130px;
            height: 140%;
            position: relative;
            width: 100%;
            
            // background-color: #333;
          }
          
          .title-block {
            margin: 0 auto;
            bottom: 41rem;
            position: relative;

            @media(max-width:550px){
              bottom: 47rem;
            }
            .title {
              @import url('https://fonts.googleapis.com/css2?family=Ibarra+Real+Nova:wght@500;600&display=swap');
              font-family: 'Ibarra Real Nova', serif;
              position: relative;
              width: 70%;
              color:#33323D;
              text-align: center;
              
              letter-spacing: 3px;
              font-size: 3.5rem;
              margin:0 auto;

              @media(max-width:400px){
                font-size: 3rem;
              }
            }
          }
          
          
          #top {
            // display: none;
            .st48,
            .st52,
            .st50{
              animation: drift-right-up 50s alternate;
            }
            .st33,
            .st51 {
              animation: drift-right-up 45s alternate;
            }
            .st49,
            .st42 {
              animation: drift-right-up 35s alternate;
            }
            
          }
          
          #bottom {
            // display: none;
            .st11,
            .st56 {
              animation: drift-down 50s alternate;
            }
            .st53,
            .st54{
              animation: drift-right 135s alternate;
            }
            .st55,
            .st36,
            .st33 {
              animation: drift-down 85s alternate;
            }
            .st51 {
              animation: drift-down-right 85s alternate;
            }
          }
          
          #nearright {
            // display: none;
            .st40,
            .st43,
            .st33{
              animation: drift-right 85s;
            }
            .st41,
            .st44 {
              animation: drift-right-up 85s;
            }
            .st42,
            .st45,
            .st46,
            .st55,
            .st47{
              animation: drift-right 105s;
            }
          }
          
          #farright {
            // display: none;
            .st54,
            .st58,
            .st59{
              animation: drift-right 35s;
            }
            .st57,
            .st51 {
              animation: drift-right-up 30s;
            }
          }
          
          
          @keyframes drift-right {
            100% {
              transform: translate(2000px, -20px);
            }
          }
          
          @keyframes drift-right-up {
            100% {
              transform: translate(2000px, -250px);
            }
          }
          
          @keyframes drift-up {
            100% {
              transform: translate(10px, -1450px);
            }
          }
          
          @keyframes drift-up-right {
            100% {
              transform: translate(175px, -1550px);
            }
          }
          
          @keyframes drift-down {
            100% {
              transform: translate(2000px, 1220px);
            }
          }
          
          @keyframes drift-down-right {
            100% {
              transform: translate(1450px, 320px);
            }
          }
    
    }


    .presentation-container{
    background-color: #FAFAFA;
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 35%;
    padding-right: 5rem;

    @media(max-width: 1150px) {
        width:400px ;
        padding-right:70px ;
        }   

        @media(max-width: 800px) {
            width:300px ;
            padding-right:20px ;
            }

            @media(max-width: 575px) {
                width: 90%;
                };
            
    .presentation-phrase{
        text-align: start;
        font-size: 3.4rem;
        letter-spacing: -1.2px;
        color: #33323D;



    @media(max-width: 1400px) {
        font-size: 2.7rem;
        }   

        @media(max-width: 1150px) {
            font-size: 2.2rem;
            }
            
            @media(max-width: 800px) {
                font-size: 1.7rem;
                }   

                @media(max-width: 575px) {
                    font-size: 2.5rem;
                    
                    }
    }

    .homepage-button{
        width: 40%;
        display: flex;
        align-items: center;
        border: none;
        padding: 0;
        background-color: none;

        @media(max-width: 575px) {
            width: 45%;
            };

            @media(max-width: 360px) {
                margin-bottom: 2rem;
                };
    


       

        .arrow-down{
            padding: 0.5rem;
            height: 49.65px;
            display: flex;
            align-items: center;
            background-color: #1C3444;
            padding-left: 1rem;
            padding-right: 1rem;
            transition: background-color 0.4s ease;

            @media(max-width: 1150px) {
                height: 35px;
                padding-left: 0.5rem;
                padding-right: 0.5rem;
                }
                
                @media(max-width: 575px) {
                    padding: 1.4rem;
                    
                    };
             

        }

        .about-me-button{
            color: white;
            background-color: #203A4C;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 50px;
            width: 100%;
            padding: 0 6rem;
            font-family: 'Public Sans',sans-serif;
            white-space: nowrap;
            font-weight: 600;
            letter-spacing: 1.4px;
            transition: background-color 0.4s ease;

            @media(max-width: 1150px) {
                height: 35.4px;
                padding: 1rem;
                padding-left: 20px;
                padding-right: 20px;
                font-size: 0.7rem;
                }
                
                @media(max-width: 575px) {
                    padding: 1.4rem;
                    
                    };

        
        }

        &:hover .about-me-button{
            background-color:#5FB4A2!important;
            cursor: pointer;
            transition: background-color 0.4s ease; 
        }

        &:hover .arrow-down{
            background-color:#4D9B8B!important;
            cursor: pointer;
            transition: background-color 0.4s ease;
            
            
        }

        &:hover g{
            stroke:white!important;
            cursor: pointer;
            transition: stroke 0.4s ease;
            
        }
        g {
            transition: stroke 0.4s ease;
        }

    
    }

    }


}

.containerInformation{
    padding-top:5rem;
}
