
    .container-image-home{
        width: 35rem;
        height: 40rem;
        background-size: cover;
        z-index: 20;
        position: relative;
        

        @media (max-width: 1200px){
       background-position: center center;
       width: 40%;
       
        }
        
        @media (max-width: 750px){
            width: 100%;
            height: 20rem;
            background-position: center center;
            } 
    }

    .containe-image-portfolio-crown-clothing{
        width: 39.75rem;           
        height: 31.25rem;
        
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url("https://morning-aroma.s3.us-east-2.amazonaws.com/elegant-hopper-860fd7.netlify.app_-min.png");
        
        @media (max-width: 1100px){
            background-position: center center;
            width: 100%;
            height: 35rem;
             }

             @media (max-width: 800px){
            
                height: 25rem;
                 }

                 @media (max-width: 570px){
            
                    height: 20rem;
                     }

                     @media (max-width: 475px){
            
                        height: 15rem;
                         }

                         @media (max-width: 320px){
            
                            height: 12rem;
                             }
    

             
    }
    .containe-image-portfolio-jobiendo{
        width: 39.75rem;           
        height: 31.25rem;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url("https://photos-webapp-portfolio.s3.us-east-2.amazonaws.com/master_optimized.d2ifz8wtm80ke3.amplifyapp.com_.png");
        
        @media (max-width: 1100px){
            background-position: center center;
            width: 100%;
            height: 35rem;
             }

             @media (max-width: 800px){
            
                height: 25rem;
                 }

                 @media (max-width: 570px){
            
                    height: 20rem;
                     }

                     @media (max-width: 475px){
            
                        height: 15rem;
                         }

                         @media (max-width: 320px){
            
                            height: 12rem;
                             }
    

             
    }