.App {
  text-align: center;
  height: 100vh;
  margin: 0 auto;
  display: grid;
  grid-template-rows: auto 1fr auto;
  
  
}
.max-width{
  max-width: 85rem;
  margin: 0 auto;
  width: 100vw;

}





