.container-info {
    display: flex;
    margin: 0 auto;
    width: 90%;
    font-size: 1.7rem;
    justify-content: space-between;


    .portfolio-button{
    font-size: 0.9rem;
    margin-bottom: 5rem;
    }

    @media (max-width: 750px){
        display: block;
        z-index: 10;
        } 

   
  

    .text-button-container{
        text-align: left;
        width: 40%;
        border-block-color: #DCDCDE;
        border-top: 1px solid;
        border-bottom: 1px solid;
        z-index: 10;
        display: flex;
    flex-direction: column;
    justify-content: space-between;


        @media (max-width: 1200px){
            width: 50%;
            } 

            @media (max-width: 750px){
                margin-top: 3rem;
                width: 100%;
                height: 40rem;
                } 
    }
    
    .text-button-portfolio{
        text-align: left;
        width: 30%;
        margin: 0 auto;
        border-block-color: #DCDCDE;
        border-top: 1px solid;
        border-bottom: 1px solid;


            @media (max-width: 750px){
                margin-top: 3rem;
                width: 100%;
                height: 40rem;
                } 

    }
}

.container-info-portfolio{
    display: flex;
    margin: 0 auto;
    width: 90%;
    margin-top: 5rem;
    font-size: 1.7rem;
   

    @media (max-width: 1100px){
            display: block;
    } 

    


    .portfolio-button{
        font-size: 0.9rem;
        margin-bottom: 5rem;
        }
    
        @media(max-width: 575px) {
            display: block;
    
            };
    
        .text-button-container{
            text-align: left;
            width: 30%;
            margin: 0 auto;
            border-block-color: #DCDCDE;
            border-top: 1px solid;
            border-bottom: 1px solid;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

    
            @media (max-width: 1200px){
                width: 50%;
                } 
    
                @media (max-width: 750px){
                    margin-top: 3rem;
                    width: 100%;
                    height: 30rem;
                    } 
        }
        
        .text-button-portfolio{
            text-align: left;
            width: 30%;
            margin: 0 auto;
            border-block-color: #DCDCDE;
            border-top: 1px solid;
            border-bottom: 1px solid;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        
        
        
    
                @media (max-width: 1100px){
                    margin-top: 3rem;
                    width: 100%;
                    } 
    
        }


}