.animation{
    height: 73.4vh;
    @media(max-width:350px){
        height: 90vh;
    }
    
    .animated{
        margin: 0 auto;
        margin-top: 6rem;
    }

    .thank-you{
        margin: 0;
        color: #33323D;
        font-size: 4rem;
        margin-bottom: 1rem;
    }

    .thank-you-text{
        font-size: 1.3rem;
        line-height: 2rem;
        letter-spacing: 0;
        opacity: .8;
        margin-bottom: 3rem;
        margin-top: 0;
    }
}
@keyframes bounce-in-top {
    0% {
      -webkit-transform: translateY(-500px);
              transform: translateY(-500px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
      opacity: 0;
    }
    38% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
      opacity: 1;
    }
    55% {
      -webkit-transform: translateY(-65px);
              transform: translateY(-65px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    72% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    81% {
      -webkit-transform: translateY(-28px);
              transform: translateY(-28px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    90% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    95% {
      -webkit-transform: translateY(-8px);
              transform: translateY(-8px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
}
 
@keyframes bounce-out-top {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    5% {
      -webkit-transform: translateY(-30px);
              transform: translateY(-30px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    15% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    25% {
      -webkit-transform: translateY(-38px);
              transform: translateY(-38px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    38% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    52% {
      -webkit-transform: translateY(-75px);
              transform: translateY(-75px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    70% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    85% {
      opacity: 1;
    }
    100% {
      -webkit-transform: translateY(-800px);
              transform: translateY(-800px);
      opacity: 0;
    }
  }
  .bounce-in-top {
	-webkit-animation: bounce-in-top 1.1s both;
	        animation: bounce-in-top 1.1s both;
}
.bounce-out-top {
	-webkit-animation: bounce-out-top 1.5s both;
	        animation: bounce-out-top 1.5s both;
}

